.menu {
    display: flex;
    flex-direction: row;

    box-sizing: border-box;
    padding-top: 15px;
    padding-bottom: 15px;
}

.menu button, .menu span {
    margin-right: 15px; 
}

.fill {
    flex: 1 1 auto;
}

.title {
    box-sizing: border-box;
    padding-right: 15px;
    font-size: 1.4rem;
    font-weight: 600;
}

.dropdown {
    position: relative;
    box-sizing: border-box;
}

.dropdown .dropdownMenuContainer {
    box-sizing: border-box;
    padding-top: 4px;
    position: absolute;
    bottom: 0;
    right: -2px;
    transform: translateY(100%);
}

.dropdown .dropdownMenuContainer .dropdownMenu {
    background: #ffffff;
    border: 2px solid #000000;
    border-radius: 10px;
    overflow: hidden;
}

.dropdown .dropdownMenuContainer .dropdownMenu .dropdownMenuItem {
    box-sizing: border-box;
    display: block;
    padding: 8px;
    font-size: 1.2rem;
    text-align: right;
    width: 100%;
    white-space: nowrap;

    text-decoration: none;
    color: #000000;
}

.dropdown .dropdownMenuContainer .dropdownMenu .dropdownMenuItem:hover {
    background: #f0f0f0;
    cursor: pointer;
}