.header {
    font-size: 2rem;
    font-weight: 600;
    padding: 8px;
    padding-bottom: 6px;
    text-align: center;
    background-color: #ffffff;
    border-bottom: 2px solid #000000;
}

.header:hover {
    background-color: #e2e2e2;
    border-bottom: 2px solid #000000;
    cursor: pointer;
}

.header.active {
    background-color: #e2e2e2;
    border-bottom: 2px solid #000000;
}

.header.active:hover {
    background-color: #e2e2e2;
    border-bottom: 2px solid #000000;
    cursor: pointer;
}