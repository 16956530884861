.formContainer {
    position: absolute;
    right: 0;
    top: 0;

    box-sizing: border-box;
    padding: 15px;
    width: 100%;
    max-width: 420px;
}

.message {
    background: #ffffff;
    border: 2px solid #000000;
    box-sizing: border-box;
    padding: 8px;
    font-size: 1.2rem;
    margin-top: 15px;
    color: red;
}

.okMessage {
    margin-top: 15px;
    color: green;
    font-weight: 600;
}