/* body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

html, body {
  font-family: Helvetica, Arial, sans-serif;
  /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 12px;

  margin: 0;
  padding: 0;
}

.container {
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  max-width: 1600px;
}

.container-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  background: url(./assets/TAAPVisitorBookCover.png) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.container-1400 {
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  max-width: 1400px;
}

.container-1200 {
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  max-width: 1200px;
}

.container-1000 {
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  max-width: 1000px;
}

.container-skinny {
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  max-width: 800px;
}

.container-tiny {
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  max-width: 300px;
}

.container-tiny-center {
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  max-width: 300px;
  margin: auto;
}

.container-fullwidth {
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-justify-space-between {
  justify-content: space-between;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-self-end {
  justify-self: flex-end;
}

.flex-align-self-center {
  align-self: center;
}

.flex-align-items-end {
  align-items: flex-end;
}

.flex-align-self-end {
  align-self: flex-end;
}

.flex-align-self-start {
  align-self: flex-start;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-fill-10 {
  flex: 1 1 10%;
}

.flex-fill-20 {
  flex: 1 1 20%;
}

.flex-fill-33 {
  flex: 1 1 33.333333%;
}

.flex-fill-40 {
  flex: 1 1 40%;
}

.flex-fill-45 {
  flex: 1 1 45%;
}

.flex-fill-50 {
  flex: 1 1 50%;
}

.flex-fill-60 {
  flex: 1 1 60%;
}

.flex-title {
  font-size: 1.4rem;
  align-self: center;
  font-weight: 600;
}

.flex-large-title {
  font-size: 2rem;
  align-self: center;
  font-weight: 600;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: 600;
}

.text-12 {
  font-size: 1.2rem;
}

.text-14 {
  font-size: 1.2rem;
}

.text-20 {
  font-size: 2rem;
}

.text-24 {
  font-size: 2.4rem;
}

.text-30 {
  font-size: 3rem;
}

.text-underlined {
  text-decoration: underline;
}

.margin-15 {
  margin: 15px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-top-0 {
  margin-top: 0px;
}

.margin-bottom-0 {
  margin-bottom: 0px;
}

.margin-left-0 {
  margin-left: 0px;
}

.margin-right-0 {
  margin-right: 0px;
}

.padding-15 {
  box-sizing: border-box;
  padding: 15px;
}

.full-width{
  width: 100%;
}

.no-margin-top {
  margin-top: 0px;
}

.no-margin-bottom {
  margin-bottom: 0px;
}

.error-message {
  color: #cf3035;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 1.2rem;
  font-weight: 600;
}

.success-message {
  color: green;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 1.2rem;
  font-weight: 600;
}

/* BORDERED CONTAINER */

.bordered-container {
  box-sizing: border-box;
  padding: 15px;
  border: 2px solid #000000;
  border-radius: 10px;
}

/* FORM CONTROL */
.form-control {
  margin-top: 12px;
  margin-bottom: 12px;
}

.form-control.no-margin-bottom {
  margin-bottom: 0px;
}

.form-control.no-margin-top {
  margin-top: 0px;
}

.form-control .label {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 4px;
}

.form-control input {
  box-sizing: border-box;
  width: 100%;
  border: 2px solid #000000;
  padding: 8px;
  font-size: 1.2rem;
}

.form-control input[type=date] {
  box-sizing: border-box;
  width: 100%;
  border: 2px solid #000000;
  padding: 5px 8px;
  font-size: 1.2rem;
}

.form-control textarea {
  box-sizing: border-box;
  width: 100%;
  border: 2px solid #000000;
  padding: 7px 8px;
  font-size: 1.2rem;
  resize: none;
}

.form-control textarea.expandable {
  resize: vertical;
}

.form-control select {
  box-sizing: border-box;
  width: 100%;
  border: 2px solid #000000;
  padding: 7px 8px;
  font-size: 1.2rem;
}

/* BUTTON */
.btn {
  box-sizing: border-box;
  font-size: 1.2rem;
  font-weight: 400;
  border: 2px solid #000000;
  padding: 8px 12px;
  background-color: #ffffff;
  user-select: none;
  border-radius: 10px;

  color: #000000;

  outline: none;
  word-break: keep-all;
}

.btn.btn-disabled {
  background-color: #f0f0f0 !important;
  pointer-events: none;
  position: relative;
}

a.btn {
  text-decoration: none;
}

.btn:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.btn.red-button {
  color: #cf3035;
  border-color: #cf3035;
}

.btn.red-button:hover {
  color: #ff0000;
  border-color: #ff0000;
  background-color: rgba(255, 0, 0, 0.2);
}

.btn.loading {
  background-color: #f0f0f0 !important;
  color: transparent !important;
  min-height: 20px;
  pointer-events: none;
  position: relative;
}

.btn.loading::after {
  animation: loading 500ms infinite linear;
  border: 2px solid #000000;
  border-radius: 50%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  
  height: 20px;
  width: 20px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* TABLE */
.table {
  box-sizing: border-box;

  margin-top: 15px;
  margin-bottom: 15px;

  width: 100%;
  text-align: center;
  font-size: 1rem;
  border-top: 1px solid #e2e2e2;
  border-left: 1px solid #e2e2e2;
  border-right: 1px solid #e2e2e2;

  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

.table.table-align-text-left {
  text-align: left;
}

.table.table-align-text-left thead tr th {
  box-sizing: border-box;
  padding-left: 8px;
  padding-right: 8px;
}

.table.table-align-text-left tbody tr td {
  box-sizing: border-box;
  padding-left: 8px;
  padding-right: 8px;
}

.table.table-font-large {
  font-size: 1.3rem;
}

.table.table-font-large tbody tr td {
  font-size: 1.8rem;
}

.table.table-shaded-header thead tr {
  background-color: #f0f0f0;
}

.table thead tr {
  border-bottom: 1px solid #e2e2e2;
}

.table thead tr th {
  box-sizing: border-box;
  padding: 8px 4px;

  word-wrap: break-word;
}

.table tbody tr td.table-cell-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table.table-striped tbody tr:nth-child(even) {
  background-color: #f0f0f0;
}

.table.table-clickable tbody tr:hover {
  cursor: pointer;
  opacity: 0.6;
}

.table.table-highlight-gray tbody tr:hover {
  cursor: pointer;
  background-color: #e2e2e2 !important;
  opacity: 1;
}

.table.table-clickable-shaded tbody tr:hover {
  cursor: pointer;
  background-color: #e2e2e2;
}

.table tbody tr td {
  box-sizing: border-box;
  padding: 8px 4px;
  border-bottom: 1px solid #e2e2e2;

  word-break: break-word;
}

/* PHOTO VIEWER */

.photo-viewer-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  background-color: rgba(0, 0, 0, 0.7);
}

.photo-viewer {
  width: 512px;
  height: 512px;

  text-align: center;
  white-space: nowrap;
  position: relative;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  user-select: none;
}

.photo-viewer-spacer {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.photo-viewer-photo {
  max-width: 512px;
  max-height: 512px;
  width:auto;
  height:auto;

  vertical-align: middle;
  user-select: none;

  image-orientation: from-image;
}

/* VISITOR PHOTO */
.visitor-photo {
  max-width: 64px;
  max-height: 64px;
  width:auto;
  height:auto;

  vertical-align: middle;

  cursor: pointer;

  image-orientation: from-image;
}

.visitor-photo-wrapper {
  width: 64px;
  height: 64px;

  text-align: center;
  white-space: nowrap;
  position: relative;

  margin: auto;
}

.visitor-photo-spacer {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}