.loading {
    background-color: transparent;
    color: transparent;
    
    height: 40px;
    width: 40px;

    pointer-events: none;
    position: relative;

    animation: loading 500ms infinite linear;
    border: 2px solid #000000;
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    
    margin: auto;
}
  
@keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}