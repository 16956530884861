.actionButton {
    margin-left: 15px;
    margin-right: 15px;
}

.error {
    color: red;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 15px;
}