.checkbox {
    display: inline-block;
    box-sizing: border-box;
    border: 2px solid #000000;
    padding: 8px;
    font-size: 1.2rem;
    cursor: pointer;
    user-select: none;
    outline: none;
    vertical-align: top;
    background-color: #ffffff;
}

.checkbox .checkboxContent {
    width: 1.2rem;
    height: 1.2rem;
    background: #ffffff;
    user-select: none;
    outline: none;
}

.checkbox .checkboxContent.checkboxChecked {
    background: #000000;
}

.sideLabel {
    display: inline-block;
    box-sizing: border-box;
    padding: 10px;
    font-size: 1.2rem;
    margin-left: 8px;
    vertical-align: top;
}

.sideLabel .sideLabelContent {
    height: 1.2rem;
    outline: none;
}