.container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.label {
  font-size: 1.4rem;
}

.value {
  font-weight: 600;
  font-size: 2rem;
}