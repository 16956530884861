.visitorPanelOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  background-color: rgba(0, 0, 0, 0.7);
}

.visitorPanel {
  box-sizing: border-box;
  border: 2px solid #000000;
  padding: 30px;
  background: #ffffff;

  width: 80%;
  max-width: 1000px;
  height: 80%;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  overflow-y: auto;
}

.visitorPanelNoPhoto {
  max-width: 600px;
}

.visitorPanelWithPhoto {
  max-width: 1000px;
}

.visitorPanelPhotoWrapper {
  width: 100%;
  max-width: 512px;

  text-align: right;
  white-space: nowrap;

  /* margin: auto; */
  margin-right: 0px;
  margin-left: auto;
}

.visitorPanelPhoto {
  width: 100%;
  height: auto;
  user-select: none;

  image-orientation: from-image;
}

.visitorPanelDetailsWrapper {
  border: 1px solid gray;
  box-sizing: border-box;
  padding: 15px;
}