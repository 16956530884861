.modal {
    box-sizing: border-box;
    padding: 15px;

    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    top: 50%;
    transform: translateY(-50%);

    width: 100%;
    max-width: 600px;

    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
    max-height: 100%;
}

.modal .content {
    border: 2px solid #000000;
    border-radius: 10px;
    overflow: hidden;

    padding: 15px;
    background-color: #ffffff;
    box-sizing: border-box;

    overflow-y: auto;
}


.overlay {
    z-index: 1;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
    background-color: rgba(0, 0, 0, 0.6);
}