.saveMessage {
    margin-left: 15px;
    font-size: 1.4rem;
    font-weight: 600;
}

.saveMessage.success {
    color: green;;
}

.saveMessage.error {
    color: red;
}