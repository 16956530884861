
.infoContent {
    margin-left: 10px;
    margin-right: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.locCodeHeader {
    font-size: 1.8rem;
    font-weight: 600;
}

.locCode {
    font-size: 5rem;
}

.marginRight15 {
    margin-left: 15px;
}

.visitorPhoto {
    max-width: 64px;
    max-height: 64px;
    width:auto;
    height:auto;

    vertical-align: middle;

    cursor: pointer;

    image-orientation: from-image;
}

.visitorPhotoWrapper {
    width: 64px;
    height: 64px;

    text-align: center;
    white-space: nowrap;
    position: relative;

    margin: auto;
}

.visitorPhotoSpacer {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.photoViewerOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
    background-color: rgba(0, 0, 0, 0.7);
}

.photoViewer {
    width: 512px;
    height: 512px;

    text-align: center;
    white-space: nowrap;
    position: relative;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    user-select: none;
}

.photoViewerSpacer {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.photoViewerPhoto {
    max-width: 512px;
    max-height: 512px;
    width:auto;
    height:auto;

    vertical-align: middle;
    user-select: none;

    image-orientation: from-image;
}

.error {
    color: #ff0000;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 15px;
}

.addVisitorFormContent {
    margin-top: 15px;
}

.visitorInfoBanner {
    box-sizing: border-box;
    padding: 8px 15px;

    background: #ffffff;
    border-top: 1px solid #000000;

    font-size: 2rem;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}